import './App.css';

import Home from './pages/home';

import { BrowserRouter, Routes, Route } from 'react-router-dom';

export default function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/">
                    <Route index element={<Home/>} />
                </Route>
            </Routes>
        </BrowserRouter>
    )
}