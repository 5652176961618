import React, { useState, useEffect } from 'react';
const mulesoftHost = window._env_.REACT_APP_MULESOFT_BASE_URL

export function GetGuidelinesForUse() {
    const accessToken = localStorage.getItem("g3-access_token");
    const [data, setData] = useState("");
    useEffect(() => {
        const fetchData = async() => {
            const response = await fetch(mulesoftHost + '/guidelines-for-use', {
                method: 'GET',
                headers: {
                    authorization: 'Bearer ' + accessToken
                }
            });
            const jsonData = await response.json();
            setData(jsonData);
            const modalsWithButtonsToEnable = ["viewGuidelinesForUseModal", "promptedGuidelinesForUseModal", "declinedGuidelinesForUseModal"];
            for(let i = 0; i < modalsWithButtonsToEnable.length; i++){
                const modalWithButtonsToEnable = document.getElementById(modalsWithButtonsToEnable[i]);
                if(modalWithButtonsToEnable) {
                    let buttons = modalWithButtonsToEnable.getElementsByClassName("modal-button");
                    for(let j = 0; j < buttons.length; j++) {
                        const button = buttons[j];
                        button.style.display = "block";
                    }
                }
            }
        };
        if(accessToken !== null && accessToken !== undefined) fetchData();
    }, []);
    return data.toString();
}

export function GetConsent() {
    const accessToken = localStorage.getItem("g3-access_token");
    const [data, setData] = useState([]);
    useEffect(() => {
        async function fetchData() {
            const response = await fetch(mulesoftHost + '/guidelines-for-use-user-consent', {
                method: 'GET',
                headers: {
                    authorization: 'Bearer ' + accessToken
                }
            });
            const jsonData = await response.json();
            setData(jsonData);
            const didConsent = jsonData;
            const promptedGuidelinesForUseModal = document.getElementById("promptedGuidelinesForUseModal");
            if (promptedGuidelinesForUseModal) {
                if (didConsent) {
                    promptedGuidelinesForUseModal.style.display = "hidden";
                } else {
                    promptedGuidelinesForUseModal.style.display = "block";
                }
                localStorage.setItem("didConsent", didConsent);
                window.dispatchEvent(new Event("didConsent"));
            }
        };
        if(accessToken !== null && accessToken !== undefined) fetchData();
    }, []);
    return data;
}

export function SetConsent() {
    const accessToken = localStorage.getItem("g3-access_token");
    fetch(mulesoftHost + '/guidelines-for-use-user-consent', {
        method: 'PUT',
        headers: {
            authorization: 'Bearer ' + accessToken
        }
    })
        .then(response => response.json())
        .then(data => {
            return data;
        })
        .catch(error => {
            console.error("There was an error getting consent information!");
            console.error(error);
        });
}

export function PlaceholderModalContent() {
    return (
            <div style={{width: '800px'}}>
                <div style={{paddingBottom: '100px'}}>
                    <h3><strong><u>Guidelines for Using Artificial Intelligence (“AI”) such as MS ChatGPT</u><p/></strong></h3>
                    <p>Below are guidelines that must be followed by G3 employees when using any AI tool.</p>
                </div>
                <div className="linear-background">
                    <div className="inter-draw"></div>
                    <div className="inter-crop"></div>
                    <div className="inter-right--top"></div>
                    <div className="inter-right--middle"></div>
                    <div className="inter-right--bottom"></div>
                </div>
            </div>
    );
}