import React, { useEffect, useState, useRef, useCallback } from "react";
import { Markup } from 'interweave';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import './home.css';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import Clipboard from 'clipboard';
import gfm from 'remark-gfm';
import { FiPlus, FiMessageSquare, FiSend, FiLoader, FiCopy } from 'react-icons/fi';
import { FaRegEdit, FaRegTrashAlt, FaCheck, FaTimes, FaArrowDown, FaMicrophone, FaStop, FaPaintBrush } from 'react-icons/fa';
import { BsStars, BsArrowDownCircle, BsStarFill, BsFillMoonStarsFill, BsChatDotsFill } from 'react-icons/bs';
import { AiOutlineFilePdf } from 'react-icons/ai';
import { CiBrightnessDown } from 'react-icons/ci';
import { RiFileExcel2Line } from 'react-icons/ri';
import { HiOutlineLightningBolt, HiOutlineMail } from 'react-icons/hi';
import { IoWarningOutline } from 'react-icons/io5';
import botAvatar from '../img/openai-avatar.png';
import userAvatar from '../img/user-avatar.png';
import ModelSelector from '../components/ModelSelector';
import html2pdf from 'html2pdf.js';
import './github-dracula.css';
import { utils, writeFileXLSX } from 'xlsx';
import pptxgen from "pptxgenjs";
import { v4 as uuidv4 } from 'uuid';
import { GetGuidelinesForUse, GetConsent, SetConsent, PlaceholderModalContent } from "../components/Consent";
import { checkTokenExpiration, getAccessToken } from '../components/Cloudentity';
const GPT35_DATE = "Trained on data up to September 2021";
const GPT35_DESCRIPTION = "The most cost effective GPT-3.5 family model that is optimized for chat and completions tasks.";
const GPT35_DESCRIPTION_WITH_DATE = GPT35_DESCRIPTION + "\n" + GPT35_DATE;
const GPT4O_MINI_DATE = "Trained on data up to October 2023";
const GPT4O_MINI_DESCRIPTION = "A compact form of ChatGPT 4-o that is optimized for faster performance and lower costs.";
const GPT4O_MINI_DESCRIPTION_WITH_DATE = GPT4O_MINI_DESCRIPTION + "\n" + GPT4O_MINI_DATE;
const GPT4O_DATE = "Trained on data up to October 2023";
const GPT4O_DESCRIPTION = "Our most capable model that is great for tasks which require creativity and advanced reasoning.";
const GPT4O_DESCRIPTION_WITH_DATE = GPT4O_DESCRIPTION + "\n" + GPT4O_DATE;
const DALLE3_DATE = "Trained on data up to August 2023";
const DALLE3_DESCRIPTION = "Our most capable image generation model that can generate images with multiple objects and complex scenes.";
const DALLE3_DESCRIPTION_WITH_DATE = DALLE3_DESCRIPTION + "\n" + DALLE3_DATE;

function DisableTabbingOutsideOfConsentModals() {
  const modals = ["promptedGuidelinesForUseModal", "declinedGuidelinesForUseModal"];
  for(let i = 0; i < modals.length; i++)
  {
    const modalId = modals[i];
    const modal = document.getElementById(modalId);
    if(modal) {
      let focusableElements = modal.querySelectorAll('a[href], button:not([disabled]), textarea, input[type="text"], input[type="radio"], input[type="checkbox"], select');
      let firstFocusableElement = focusableElements[0];
      let lastFocusableElement = focusableElements[focusableElements.length - 1];
      firstFocusableElement.focus();
      modal.addEventListener('keydown', (e) => {
        if (e.key === 'Tab') {
          if (e.shiftKey) {
            if (document.activeElement === firstFocusableElement) {
              e.preventDefault();
              lastFocusableElement.focus();
            }
          } else {
            if (document.activeElement === lastFocusableElement) {
              e.preventDefault();
              firstFocusableElement.focus();
            }
          }
        }
      });
    }
  }
}

function updateAppNameSpan(selectedModel) {
  const appNameSpan = document.querySelector('.appNameSpan');
  const modelDescriptionSpan = document.querySelector('.modelDescriptionSpan');
  if (appNameSpan) {
    if (["gpt", "gpt-4o"].includes(selectedModel)) {
      appNameSpan.innerText = 'ChatGPT 4-o' + environmentTitle;
      modelDescriptionSpan.innerText = GPT4O_DESCRIPTION_WITH_DATE;
    } else if (selectedModel === "gpt-4o-mini") {
      appNameSpan.innerText = 'ChatGPT 4-o Mini' + environmentTitle;
      modelDescriptionSpan.innerText = GPT4O_MINI_DESCRIPTION_WITH_DATE;
    } else if (selectedModel === "gpt-35-turbo") {
      appNameSpan.innerText = 'ChatGPT 3.5 Turbo' + environmentTitle;
      modelDescriptionSpan.innerText = GPT35_DESCRIPTION_WITH_DATE;
    } else if (["dall-e-3", "dall-e-3"].includes(selectedModel)) {
      appNameSpan.innerText = 'DALL-E 3' + environmentTitle;
      modelDescriptionSpan.innerText = DALLE3_DESCRIPTION_WITH_DATE;
    }
  }
}

// Setup the title of the app to properly reflect the environment
const environmentName = window._env_.REACT_APP_ENVIRONMENT_NAME
let appTitle = "";
let environmentTitle;
if(environmentName !== "PRD") {
  environmentTitle = " (" + environmentName + ")";
} else {
  environmentTitle = "";
}
appTitle = "ChatGPT" + environmentTitle;

function ProtectedComponent() {

  //run token auth check every tokenCheckInterval to ensure login is valid
  const tokenCheckIntervalMinutes = 1;
  const tokenCheckInterval = tokenCheckIntervalMinutes * 60 * 1000;
  setInterval(function() {
    checkTokenExpiration();
  }, tokenCheckInterval);

  const placeholderModalContent = PlaceholderModalContent();
  const modalContent = GetGuidelinesForUse();
  const [apiData, setApiData] = useState(null);

  const [question, setQuestion] = useState({});
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  const [history, setHistory] = useState([]);
  const textareaRef = useRef(null);
  const imageNumberRef = useRef(null);

  const scrollBottomBtnRef = useRef(null);

  const [historyCursor, setHistoryCursor] = useState(-1);


  const [hasNewLine, setHasNewLine] = useState(false);

  const endOfMessageRef = useRef(null);

  // Add a state variable to control the visibility of the sidebar
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const [promptSubmitted, setPromptSubmitted] = useState(false);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);

  // Add a state variable to control the visibility of the scroll to bottom button
  const [showScrollButton, setShowScrollButton] = useState(false);

  // Function to toggle the sidebar visibility
  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  }

  const handleChange = (event) => {
    setInput(event.target.value);
    autoResizeTextarea();
  };


  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (!e.shiftKey && !hasNewLine) {
        e.preventDefault();
        SubmitQuestion(e);
      } else {
        setHasNewLine(true);
      }
    }


    // Filter messages array to only include user messages

    if (e.key === 'ArrowUp') {
      const userMessages = messages.filter((message) => message.role !== 'assistant');
      // Move up in user message history
      if (historyCursor < userMessages.length - 1) {
        setHistoryCursor((prevCursor) => prevCursor + 1);
        setInput(userMessages[userMessages.length - historyCursor - 2].content);
      }
    } else if (e.key === 'ArrowDown') {
      const userMessages = messages.filter((message) => message.role !== 'assistant');
      // Move down in user message history
      if (historyCursor > 0) {
        setHistoryCursor((prevCursor) => prevCursor - 1);
        setInput(userMessages[userMessages.length - historyCursor].content);
      } else if (historyCursor === 0) {
        // Clear the input when reaching the end of the history
        setHistoryCursor(-1);
        setInput('');
      }
    }

  };


  const autoResizeTextarea = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = textareaRef.current.scrollHeight + 'px';
      if (scrollBottomBtnRef.current && textareaRef.current.scrollHeight <= 200) {
        scrollBottomBtnRef.current.style.bottom = (100 + textareaRef.current.scrollHeight) + "px";
      }
    }
  };

  const scrollToBottom = () => {
    endOfMessageRef.current?.scrollIntoView({behavior: 'smooth'});
  };

  window.addEventListener("didConsent", () => {
    const localStoreDidConsent = localStorage.getItem("didConsent") ? (localStorage.getItem("didConsent") === "true") : false;
    const promptedGuidelinesForUseModal = document.getElementById("promptedGuidelinesForUseModal");
    promptedGuidelinesForUseModal.style.display = "block";
    // Get consent for the account ID
    if(localStoreDidConsent) {
      promptedGuidelinesForUseModal.style.display = "none";
    }
  });

  // CHAT TOPICS
  const [chatTopics, setChatTopics] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("chatTopics");
    const initialValue = JSON.parse(saved);
    return initialValue || [];
  });


  useEffect(() => {
    // storing input name
    localStorage.setItem("chatTopics", JSON.stringify(chatTopics));
  }, [chatTopics]);


  const [currentTopicId, setCurrentTopicId] = useState((null));

  const currentTopicIdRef = useRef(currentTopicId);
  useEffect(() => {
    currentTopicIdRef.current = currentTopicId;
  }, [currentTopicId]);


  const addChatTopic = (topicTitle) => {
    const newTopicId = Date.now();
    setCurrentTopicId(() => newTopicId);
    const initialMessage = {
      content: input,
      role: "user",
    };
    setChatTopics((prevChatTopics) => [
      ...prevChatTopics,
      {id: newTopicId, title: topicTitle, messages: [], modelName: currentModel},
    ]);

    setMessages([]);

  };

  const switchChatTopic = (topicId) => {

    // Set the current topic ID
    setCurrentTopicId(topicId);
    setPromptSubmitted(false);
    // Find the topic with the matching ID
    const selectedTopic = chatTopics.find((topic) => topic.id === topicId);

    // Update the messages state with the messages from the selected topic
    if (selectedTopic) {
      setMessages(selectedTopic.messages);
      if (selectedTopic.modelName) {
        setCurrentModel(selectedTopic.modelName); // Update the current model
      } else {
        setCurrentModel("gpt-4o");
      }
      setModelSelectionVisible(false); // Hide the model selection list
    } else {
      console.error("No topic found with the given ID:", topicId);
    }
    setSidebarVisible(false);
    textareaRef.current.focus();
  };

  const updateChatTopicTitle = (topicId, title) => {
    setChatTopics((prevChatTopics) =>
        prevChatTopics.map((topic) =>
            topic.id === topicId ? {...topic, title} : topic
        )
    );
  };

  const startNewChatTopic = () => {
    setCurrentTopicId(null);

    setCurrentModel("gpt-4o");
    setModelSelectionVisible(true);

    setMessages([]);
    setSidebarVisible(false);
    textareaRef.current.focus();
  };


  // Add state variables for edit mode and edited title
  const [editingTopicId, setEditingTopicId] = useState(null);
  const [editedTitle, setEditedTitle] = useState('');

  // Handle edit, save, cancel, and delete actions
  const handleEditClick = (topicId) => {
    const topic = chatTopics.find((topic) => topic.id === topicId);
    if (topic) {
      setEditingTopicId(topicId);
      setEditedTitle(topic.title);
    } else {
      console.error(`Topic with id ${topicId} not found.`);
    }
  };

  const handleSaveClick = (topicId) => {
    // Create a copy of the chatTopics array
    const updatedChatTopics = [...chatTopics];

    // Find the index of the topic you want to update
    const topicIndex = updatedChatTopics.findIndex((topic) => topic.id === topicId);

    // Update the title of the topic at that index
    if (topicIndex !== -1) {
      updatedChatTopics[topicIndex] = {
        ...updatedChatTopics[topicIndex],
        title: editedTitle,
      };

      // Update the chatTopics state with the modified array
      setChatTopics(updatedChatTopics);
    } else {
      console.error(`Topic with id ${topicId} not found.`);
    }

    setEditingTopicId(null);
    setEditedTitle('');
  };

  const handleCancelClick = () => {
    // Reset the editing state
    setEditingTopicId(null);
    setEditedTitle('');
  };

  const handleDeleteClick = (topicId) => {

    setMessages([]);
    // Filter the chatTopics array to exclude the topic with the specified topicId
    const updatedChatTopics = chatTopics.filter((topic) => topic.id !== topicId);
    setChatTopics(updatedChatTopics);


  };


  // END CHAT TOPICS


  useEffect(() => {
    const container = document.querySelector('.main-container');
    const scrollButton = document.querySelector('.scrollButton');

    const handleScroll = () => {
      const container = document.querySelector('.main-container');
      const isAtBottom = container.scrollHeight - container.scrollTop <= container.clientHeight + 20;
      setShowScrollButton(!isAtBottom);
    };

    container.addEventListener('scroll', handleScroll);
    // Check scroll position when messages change
    handleScroll();
    return () => container.removeEventListener('scroll', handleScroll);
  }, [messages]);

  useEffect(() => {
    function handleChatClick() {
      if (sidebarVisible) {
        setSidebarVisible(false);
      }
    }

    const chatContainer = document.querySelector('.chat-container');
    chatContainer.addEventListener('click', handleChatClick);

    return () => {
      chatContainer.removeEventListener('click', handleChatClick);
    };
  }, [sidebarVisible]);

  const addBotMessage = (botCompletionTxt) => {
    if (!botCompletionTxt) return;
    setTimeout(() => {
      setMessages((prevMessages) => [
        ...prevMessages,
        {content: botCompletionTxt, role: "assistant"},
      ]);


      // Update the chatTopics object with the new messages
      setChatTopics((prevChatTopics) =>
          prevChatTopics.map((topic) =>
              topic.id === currentTopicIdRef.current
                  ? {
                    ...topic,
                    messages: [...topic.messages, {content: input, role: "user"}, {
                      content: botCompletionTxt,
                      role: "assistant"
                    }]
                  }
                  : topic
          )
      );

    }, 500);

    setHistory((prevHistory) => [
      ...prevHistory,
      {role: "assistant", content: botCompletionTxt},
    ]);
  };


  const SubmitQuestion = (e) => {
    e.preventDefault();
    if (!input || submitButtonDisabled) return;

    setSubmitButtonDisabled(true);
    setModelSelectionVisible(false); // Hide the model selection list

    setPromptSubmitted(true);
    let question = sessionStorage.getItem('question');
    sessionStorage.setItem('question', "");


    if (input != "") {


      if (currentTopicId === null) {
        addChatTopic(input.substring(0, 100));
      }

      const newMessage = {
        content: input,
        role: "user"
      };
      setMessages([...messages, newMessage]);

      setHistory((prevHistory) => [
        ...prevHistory,
        {role: "user", content: input},
      ]);


      setIsLoading(true);
      // apply system message
      let json;
      let localAccountDetails;
      if (localAccountDetails) {
        // add insights book system message
        let systemString = `
                          •	The user you are chatting with has oid ${localStorage.getItem("localAccountId")}. 
                          `;
        systemString = ""; // clear this for now
        const systemMessage = {
          content: systemString,
          role: "system"
        };
        json = JSON.stringify({"Query": input, "ReqType": currentModel, History: [systemMessage, ...messages]});
      } else {
        json = JSON.stringify({"Query": input, "ReqType": currentModel, History: messages});
      }

      // Recreate the json message one last time ... this is for the Mule port
      json = {
        messages: [
          ... (messages.map(item => ({
            role: item.role,
            content: [
              {
                type: "text",
                text: item.content
              }
            ]
          }))),
          {
            role: "user",
            content: [
              {
                type: "text",
                text: input
              }
            ]
          }
        ],
        // Balanced settings per https://community.openai.com/t/cheat-sheet-mastering-temperature-and-top-p-in-chatgpt-api/172683
        temperature: 0.5,
        top_p: 0.5
      };

      if (currentModel == "dall-e-3") {
        let imageCount = "1";
        if (imageNumberRef.current) {
          imageCount = imageNumberRef.current.value;
        }
        json = JSON.stringify({
          "prompt": input,
          "ReqType": currentModel,
          "n": parseInt(imageCount),
          "size": "1024x1024"
        });
      }

      if (!apiData) {
        let mulesoftEndpointURL = window._env_.REACT_APP_MULESOFT_BASE_URL;
        if(currentModel === "dall-e-3") {
          mulesoftEndpointURL = mulesoftEndpointURL + "/dall-e?DeploymentModel=" + currentModel;
        } else {
          mulesoftEndpointURL = mulesoftEndpointURL + "/chatgpt?DeploymentModel=" + currentModel;
        }

        const accessToken = getAccessToken();

        axios.post(mulesoftEndpointURL, json, {
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + accessToken
          }
        })
            .then(response => {

              if (response.status == 200) { // if everything came back OK
                if (currentModel === "dall-e-3") {
                  let urls = "";
                  if(typeof(response.data.data) != "undefined") {
                    response.data.data.forEach((item) => {
                      urls += item.url.toString() + "\n";
                    });
                  }
                  if(urls !== "") {
                    addBotMessage(urls);
                  } else {
                    addBotMessage(response.data.error.message.toString());
                  }
                } else {
                  addBotMessage(response.data.choices[0].message.content.toString());
                }
              } else {
                // Houston, we have a problem.
                addBotMessage(response.data.body.message);
              }

              setIsLoading(false);
              setSubmitButtonDisabled(false);
            });


      }
    }

    // Reset Text Area
    setInput('');
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
    }
    setHasNewLine(false);
    autoResizeTextarea();
    setHistoryCursor(-1);


  }

  const headerStyle = {
    display: 'flex'
  };

  const buttonStyle = {

    backgroundColor: '#09215B',
    color: 'white'
  };

  const micButtonStyle = {

    backgroundColor: '#09215B',
    color: 'white'
  };

  const micButtonStyleActive = {

    backgroundColor: 'red',
    color: 'white'
  };


  const footerStyle = {

    fontSize: '.6rem',
    color: 'black',
    textAlign: 'center',
  };

  const footerPriceStyle = {
    textAlign: 'center',
    fontSize: '.6rem',
    color: 'red'
  };

  /* SPEECH TO TEXT CODE */
  const promptSubmitBtn = useRef(null);
  const [listening, setListening] = useState(false);

  const handleStartListening = () => {
    const recognition = new window.webkitSpeechRecognition();
    recognition.interimResults = true;

    recognition.onresult = (event) => {
      const result = event.results[event.results.length - 1];
      if (result.isFinal) {
        setInput(result[0].transcript);
      }
    };

    recognition.onerror = (event) => {
      console.error('Speech recognition error:', event);
      setListening(false);
    };

    recognition.onend = () => {
      setListening(false);
      if (promptSubmitBtn.current) {
        promptSubmitBtn.current.click();
      }
    };

    recognition.start();
    setListening(true);
  };

  const handleStopListening = () => {
    if ('webkitSpeechRecognition' in window) {
      const recognition = new window.webkitSpeechRecognition();
      recognition.stop();
    }
    setListening(false);
  };

  const toggleListening = (e) => {
    e.preventDefault();
    if (listening) {
      handleStopListening();
    } else {
      handleStartListening();
    }
    return false;
  };

  /* END SPEECH TO TEXT */

  /* Model Switching */
  const [currentModel, setCurrentModel] = useState('gpt-4o');
  const modelChange = (event) => {
    setCurrentModel(event.target.value);
    // You may want to perform some operation when the model changes,
    // like refreshing the UI, etc.
  };


  const getSelectedOptionCost = (selectedModel) => {
    const selectedButton = modelButtons.find(button => button.value === selectedModel);
    if (selectedButton) {
      return selectedButton.cost;
    }

    for (const button of modelButtons) {
      if (button.dropdownOptions) {
        const selectedOption = button.dropdownOptions.find(option => option.value === selectedModel);
        if (selectedOption) {
          return selectedOption.cost;
        }
      }
    }

    return null;
  };

  useEffect(() => {
    // Code to update footerPriceElement whenever currentModel changes
    updateFooterPrice(getSelectedOptionCost(currentModel), currentModel);
  }, [currentModel]);

  const updateFooterPrice = (priceText, selectedModel) => {
    // Code to update footerPriceElement
    // Example:
    const footerPriceElement = document.querySelector('.footerPriceStyle');
    if (footerPriceElement) {
      footerPriceElement.innerText = priceText;
    }

    const textAreaElement = document.querySelector('.message-input');
    const imageCountSelectElement = document.querySelector('.imageCountSelect');

    if (selectedModel === "dall-e-3") {
      //change interface
      textAreaElement.placeholder = "Enter an image description (e.g., painting of a vineyard at sunset)...";

      const elements = document.querySelectorAll('.help-bubble');
      elements.forEach(element => {
        element.style.display = 'none';
      });

      const elements2 = document.querySelectorAll('.help-bubble-dalle');
      elements2.forEach(element => {
        element.style.display = 'block';
      });

      //reset interface
      updateAppNameSpan(selectedModel);
      autoResizeTextarea();
    } else {

      const elements = document.querySelectorAll('.help-bubble');
      elements.forEach(element => {
        element.style.display = 'block';
      });

      const elements2 = document.querySelectorAll('.help-bubble-dalle');
      elements2.forEach(element => {
        element.style.display = 'none';
      });

      //reset interface
      updateAppNameSpan(selectedModel);
      autoResizeTextarea();

      textAreaElement.placeholder = "Send a message...";
    }


  };

  const [modelSelectionVisible, setModelSelectionVisible] = useState(true);
  const modelButtons = [
    { name: 'ChatGPT', value:'gpt', icon: <BsChatDotsFill size='1em' />, dropdown:true, desc:GPT4O_DESCRIPTION_WITH_DATE,
      dropdownOptions: [
        { name: 'ChatGPT 4-o', value:'gpt-4o', icon: <BsStarFill size='1em' />, desc:GPT4O_DESCRIPTION_WITH_DATE},
        { name: 'ChatGPT 4-o Mini', value:'gpt-4o-mini', icon: <BsStars size='1em' />, desc:GPT4O_MINI_DESCRIPTION_WITH_DATE},
        { name: 'ChatGPT 3.5 Turbo', value:'gpt-35-turbo', icon: <BsFillMoonStarsFill size='1em' />, desc:GPT35_DESCRIPTION_WITH_DATE},
      ]
    },
    {
      name: 'DALL-E 3',
      value: 'dall-e-3',
      icon: <FaPaintBrush size='1em'/>,
      dropdown: false,
      desc: DALLE3_DESCRIPTION_WITH_DATE,
      dropdownOptions: []
    }
  ];

  let selectedModelObj = {};
  modelButtons.some(button => {
    let overriddenOptionValue;
    if(currentModel === "gpt") {
      overriddenOptionValue = "gpt-4o";
    } else if(currentModel === "dall-e") {
      overriddenOptionValue = "dall-e-3";
    }
    if (button.dropdownOptions) {
      const selectedOption = button.dropdownOptions.find(option => option.value === currentModel);
      if (selectedOption) {
        selectedModelObj = overriddenOptionValue ? overriddenOptionValue : selectedOption;
        return true; // Exit the loop when a match is found in dropdownOptions
      }
    }
    if (button.value === currentModel) {
      selectedModelObj = overriddenOptionValue ? overriddenOptionValue : button;
      return true; // Exit the loop when a match is found in modelButtons
    }
    return false;
  });

  const modelButtonText = (
      <>
        {selectedModelObj.icon} {selectedModelObj.name}
      </>
  );

  return (
      <div className="appContDiv">
        <div id="declinedGuidelinesForUseModal" className="modal">
          <div className="modal-content">
            <div style={{fontSize: 'xx-large', textAlign: 'center'}}>To use ChatGPT you must accept the guidelines for
              use.
            </div>
            <div className="modal-content-buttons">
              <button className="modal-button" onClick={function () {
                window.location = "https://www.g3enterprises.com/";
              }}>Exit
              </button>
              <button className="modal-button" onClick={function () {
                const promptedGuidelinesForUseModal = document.getElementById("promptedGuidelinesForUseModal");
                const declinedGuidelinesForUseModal = document.getElementById("declinedGuidelinesForUseModal");
                declinedGuidelinesForUseModal.style.display = "none";
                promptedGuidelinesForUseModal.style.display = "block";
                DisableTabbingOutsideOfConsentModals();
              }}>Return
              </button>
            </div>
          </div>
        </div>

        <div id="promptedGuidelinesForUseModal" className="modal">
          <div className="modal-content">
            <Markup emptyContent={placeholderModalContent} content={modalContent}/>
            <div className="modal-content-buttons">
              <div>
                <button className="modal-button" id="declineButton" onClick={function () {
                  const promptedGuidelinesForUseModal = document.getElementById("promptedGuidelinesForUseModal");
                  const declinedGuidelinesForUseModal = document.getElementById("declinedGuidelinesForUseModal");
                  promptedGuidelinesForUseModal.style.display = "none";
                  declinedGuidelinesForUseModal.style.display = "block";
                  DisableTabbingOutsideOfConsentModals();
                }}>Decline
                </button>
              </div>
              <div>
                <button className="modal-button" id="acceptButton" onClick={function () {
                  const promptedGuidelinesForUseModal = document.getElementById("promptedGuidelinesForUseModal");
                  promptedGuidelinesForUseModal.style.display = "none";
                  DisableTabbingOutsideOfConsentModals();
                  SetConsent();
                }}>Accept
                </button>
              </div>
            </div>
          </div>
        </div>

        <div id="viewGuidelinesForUseModal" className="modal">
          <div className="modal-content">
            <Markup emptyContent={placeholderModalContent} content={modalContent}/>
            <div className="modal-content-buttons">
              <div>
                <button className="modal-button" onClick={function () {
                  const viewGuidelinesForUseModal = document.getElementById("viewGuidelinesForUseModal");
                  viewGuidelinesForUseModal.style.display = "none";
                }}>Close</button>
              </div>
            </div>
          </div>
        </div>
        <aside className={`sidebar ${sidebarVisible ? 'visible' : 'hidden'}`}>
          <div className="sidebarHeader">

            <img src="ChatGPTLogo.png"/>
          </div>
          <button
              className="addChatBtn relative inline-flex justify-start items-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:#5D6B83 focus:z-10 bg-gray-200 rounded-l-md"
              onClick={startNewChatTopic}><FiPlus className="plus-icon"/><span>New chat</span></button>

          <div className="messageTopics">
            {chatTopics
                .sort((a, b) => b.id - a.id)
                .map((topic) => {
                  const isEditing = editingTopicId === topic.id;
                  return (
                      <div className="topicDiv" key={topic.id}>
                        <a

                            className={`messageTopic ${topic.id === currentTopicId ? 'topicSelected' : ''}`}
                            onClick={() => {
                              if (!isEditing) {
                                switchChatTopic(topic.id);
                              }
                            }}
                        >
                          <FiMessageSquare/>
                          <div className="messageTopicContent">
                            {isEditing ? (
                                <input autoFocus
                                       className="text-black"
                                       value={editedTitle}
                                       onChange={(e) => setEditedTitle(e.target.value)}
                                />
                            ) : (
                                topic.title
                            )}
                            <div className="messageTopicFade"></div>
                          </div>
                        </a>
                        {topic.id === currentTopicId && (
                            isEditing ? (
                                <>
                                  <FaCheck className="topicLeftButton" onClick={() => handleSaveClick(topic.id)}/>
                                  <FaTimes className="topicRightButton" onClick={handleCancelClick}/>
                                </>
                            ) : (
                                <>
                                  <FaRegEdit className="topicLeftButton" onClick={() => handleEditClick(topic.id)}/>
                                  <FaRegTrashAlt className="topicRightButton"
                                                 onClick={() => handleDeleteClick(topic.id)}/>
                                </>
                            )
                        )}
                      </div>
                  );
                })}
          </div>


        </aside>
        <div className={`homeDiv ${sidebarVisible ? 'sidebar-visible' : ''}`}>
          <header className={` ${sidebarVisible ? 'header-visible' : ''}`}>
            <button onClick={toggleSidebar} className="sidebar-toggle">☰</button>
          </header>

          <main className="main-container">
            <button
                style={{
                  position: 'absolute',
                  top: 7,
                  right: 21,
                  backgroundColor: "white",
                  borderRadius: 5,
                  color: "black",
                  zIndex: 999
                }}
                onClick={handleExportToPDF}
            >
              <AiOutlineFilePdf style={{fontSize: "1rem"}} title="Export to PDF"/>
            </button>

            <div className="chat-container">
              <div className={`mt-4 model-selection ${modelSelectionVisible ? 'visible' : 'hidden'}`}
                   style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <ModelSelector buttons={modelButtons} selected={currentModel} setSelected={setCurrentModel}/>
              </div>
              <div
                  className={`flex items-center justify-center gap-1 border-b border-black/10 bg-g3-blue p-3 text-white 
                  dark:border-gray-900/50 dark:bg-g3-blue dark:text-white model-name 
                  ${!modelSelectionVisible ? 'visible' : 'hidden'}`}>{modelButtonText}
              </div>
              {(!messages.length || messages.length == 0) && (
                  <div className="help-container">
                    <div className="help-header"><span className="appNameSpan">ChatGPT 4-o {environmentTitle}</span>
                      <div className="modelDescriptionSpan">{GPT4O_DESCRIPTION}<br/>{GPT4O_DATE}</div>
                    </div>
                    <div className="help-bubble-heading"><CiBrightnessDown size={36} className="helpIcon"/>Examples
                    </div>
                    <div className="help-bubble-heading"><HiOutlineLightningBolt size={36} className="helpIcon"/>Capabilities
                    </div>
                    <div className="help-bubble-heading"><IoWarningOutline size={36} className="helpIcon"/>Limitations
                    </div>
                    <div className="help-bubble">"Write a compelling product description for packaging that is both
                      consumer and environmentally friendly"
                    </div>
                    <div className="help-bubble">Retains previous discussion for reference within an ongoing
                      conversation
                    </div>
                    <div className="help-bubble">May on occasion produce inaccurate information</div>
                    <div className="help-bubble">"Craft a blog post that explores the different types of logistics
                      transportation solutions that are available throughout California"
                    </div>
                    <div className="help-bubble">Allows feedback in subsequent prompts to provide follow-up
                      corrections
                    </div>
                    <div className="help-bubble">Limited knowledge of world events after certain dates, depending on the
                      model
                    </div>
                    <div className="help-bubble">"Write a social media post on wine cork sustainability emphasizing
                      Diam Corks"
                    </div>
                    <div className="help-bubble">Will decline to answer inappropriate requests</div>
                    <div className="help-bubble">Not currently trained to produce results on G3-owned data</div>

                    <div className="help-bubble-dalle">"Create an image of a vineyard with grapes that are shaped like a
                      circle"
                    </div>
                    <div className="help-bubble-dalle">Can generate images from textual descriptions</div>
                    <div className="help-bubble-dalle">Not capable of understanding the context between prompts</div>
                    <div className="help-bubble-dalle">"Generate an image of a wine bottle and cheese tray"</div>
                    <div className="help-bubble-dalle">Can combine multiple objects into a single image</div>
                    <div className="help-bubble-dalle">Limited to generating static images and cannot create animations
                      or videos
                    </div>
                    <div className="help-bubble-dalle">"Generate an image of a picnic at the beach"</div>
                    <div className="help-bubble-dalle">Can generate images with high resolution and detail</div>
                    <div className="help-bubble-dalle">All image generation prompts must comply with the guidelines for
                      use
                    </div>

                  </div>
              )}
              <Messages key={currentTopicId} messages={messages} isLoading={isLoading} endOfMessageRef={endOfMessageRef}
                        promptSubmitted={promptSubmitted} isAtBottom={!showScrollButton} currentModel={currentModel}/>
              <div ref={endOfMessageRef}></div>
              {showScrollButton && (
                  <button
                      ref={scrollBottomBtnRef}
                      className="scrollButton"
                      onClick={scrollToBottom}
                  >
                    <FaArrowDown/>
                  </button>
              )}
            </div>
          </main>
          <footer>
            <form onSubmit={SubmitQuestion} className="input-form" style={{position: 'static'}}>

              <button className="rounded" style={listening ? micButtonStyleActive : micButtonStyle}
                      onClick={toggleListening} disabled={submitButtonDisabled}>
                {listening ? <FaStop/> : <FaMicrophone/>}
              </button>

              <textarea
                  ref={textareaRef}
                  className="message-input" // Add the className property here
                  name="questionTxt"
                  value={input}
                  onChange={handleChange}
                  placeholder="Send a message..."
                  rows="1"
                  onKeyDown={handleKeyDown}
              />

              <button className="rounded-md ml-2" style={buttonStyle} type="submit" disabled={submitButtonDisabled}
                      key={currentTopicId} ref={promptSubmitBtn}>
                {submitButtonDisabled ? <FiLoader/> : <FiSend/>}
              </button>
            </form>
            <div className="footerStyle">
              <a style={{textDecoration: "underline", fontWeight: "bold"}}
                 onClick={function () {
                   const viewGuidelinesForUseModal = document.getElementById("viewGuidelinesForUseModal");
                   viewGuidelinesForUseModal.style.display = "block";
                 }} style={{cursor: "pointer", textDecoration: "underline"}}>Guidelines for use.</a> ChatGPT may
              produce inaccurate information about people, places, or facts and is not currently trained to produce
              results on G3-owned data.
            </div>
          </footer>
        </div>
      </div>

  )
}

const useTypingEffect = (text, delay, role, endOfMessageRef, isAtBottom, noEffect) => {
  const [displayedText, setDisplayedText] = useState(role == 'assistant' && !noEffect ? '' : text);
  const [currentIndex, setCurrentIndex] = useState(role == 'assistant' && !noEffect ? 0 : text.length);
  const [showCursor, setShowCursor] = useState(false);

  const debouncedScroll = useDebounce(() => {
    if (isAtBottom) {
      endOfMessageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, 100, [endOfMessageRef, isAtBottom]); // Adjust the debounce delay as needed


  useEffect(() => {
    if (role != "assistant" || noEffect) return;


    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => prevIndex + 5);

      if (currentIndex >= text.length) {
        clearInterval(interval);

      }
    }, delay);

    return () => {
      clearInterval(interval);
    }
  }, [text, delay, role]);

  useEffect(() => {
    let showC = false;
    if (currentIndex <= text.length && role == "assistant" && !noEffect) {
      showC = true;
    } else {
      showC = false;
    }
    setDisplayedText(text.substring(0, currentIndex) + (showC ? '\u275A' : ''));
    if (role != "assistant" || noEffect) return; // only use debouncedScroll for bot messages
    if (currentIndex <= text.length) {
      debouncedScroll();
    }
  }, [currentIndex, text, debouncedScroll]);

  return displayedText;
};



const isNumericalList = (text) => {
  const lines = text.split('\n');
  return lines.every((line) => /^\d+\.\s.*$/.test(line));
};

const formatNumericalList = (response) => {
  const lines = response.split('\n');
  const formattedLines = lines.map((line) => {
    const match = line.match(/^\d+\.\s(.*)$/);
    if (match) {
      return `<li>${match[1]}</li>`;
    }
    return line;
  });
  return `<ol>${formattedLines.join('')}</ol>`;
};

const Messages = ({ messages, isLoading, endOfMessageRef, promptSubmitted, isAtBottom, currentModel }) => {
  const lastMessageRef = useRef(null);

  useEffect(() => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  return (
    <div className="messages">
      {messages.map((message, index) => {
        const isLastMessage = index === messages.length - 1;

        return (
          <React.Fragment key={index}>
            <Message
              content={message.content}
              role={message.role}
              ref={isLastMessage ? lastMessageRef : null}
              endOfMessageRef={endOfMessageRef}
              applyTypingEffect={index === messages.length - 1}
              promptSubmitted={promptSubmitted}
              isAtBottom={isAtBottom}
              currentModel={currentModel}
            />
            {isLoading && isLastMessage && (
              <div className="loading-message"><img src="typing-clear.gif" style={{ height: '32px' }} /></div>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

const Message = React.forwardRef(({ content, role, endOfMessageRef, applyTypingEffect, promptSubmitted, isAtBottom, currentModel }, ref) => {
  const className = role === 'assistant' ? 'message bot' : 'message user';
  const hasCodeBlock = content.includes('```');
  let noEffect = !applyTypingEffect || !promptSubmitted || hasCodeBlock;
  let typingEffectText = useTypingEffect(content, 0, role, endOfMessageRef, isAtBottom, noEffect);

  const isURL = (content) => {
    try {
      new URL(content);
      return true;
    } catch (error) {
      return false;
    }
  };
  const isContentURL = isURL(content);

  const avatar = role === 'assistant' ? botAvatar : userAvatar;
  const isList = isNumericalList(content);
  const formattedContent = typingEffectText.split('\n').map((line, index) => (
    <p key={index}>{line}</p>
  ));

  const formattedListContent = formatNumericalList(typingEffectText);

  let dalleContent = "";
  if (currentModel == "dall-e-3") {
    // DALLEURLS = content.split("\n"); // split the string by line breaks
    // DALLEURLS.pop(); // remove the last element from the array
    dalleContent = content;
  }



  return (
    <div className={className} ref={ref}>

      <div className="message-content">
        <div className="avatar-div">
          <img align="middle" className="avatar" style={{ borderRadius: 3, height: 36, width: 36, marginRight: 20 }} src={avatar} alt="avatar" />
        </div>
        <div className="content-div">
          {hasCodeBlock ? (
            <MarkdownMessage content={content} />
          ) : isList ? (
            <div dangerouslySetInnerHTML={{ __html: formattedListContent }} />
          ) : (currentModel == "dall-e-3") ? (
                isContentURL ? (
                  <div className="DALLEContainer">
                    <div className="dalleImage" key={dalleContent}>
                      <img className="w-25" src={dalleContent} />
                      <div className="flex space-x-4">
                        <a target="_blank" title="Download Image" href={dalleContent} download className="flex align-middle">
                          <button className="w-fit rounded-md text-black border border-solid border-slate-400 flex leading-4 mt-4
                          "> <BsArrowDownCircle size='1em' /></button>
                        </a>
                        <a target="_blank" title="Share Image in new email message" href={`mailto:?subject=Image%20Download&body="${encodeURIComponent(dalleContent)}"`}>
                          <button className="w-fit rounded-md text-black border border-solid border-slate-400 flex leading-4 mt-4">
                            <HiOutlineMail size='1em' />
                          </button>
                        </a>
                      </div>
                    </div>
                  </div>
                ) : (
                  <MarkdownMessage content={dalleContent} />
                )
          ) : (
              <ReactMarkdown
                  remarkPlugins={[gfm]}
                  children={typingEffectText}
                  className="markdown"
                  breaks={role === 'assistant' ? false : true}
                  components={{
                    code({ node, inline, className, children, ...props }) {
                      const match = /language-(\w+)/.exec(className || '');
                      let language = "";
                      if(match){
                        language = match[1];
                      }
                      return !inline ? (
                          <CodeBlock language={language} value={children} {...props} />
                      ) : (
                          <code className={className} {...props}>
                            {children}
                          </code>
                      );
                    },
                    table({ node, inline, className, children, ...props }) {

                      return (
                          <TableBlock children={children} className={className} {...props} ></TableBlock>
                      );
                    },
                  }}
              />
          )}
        </div>
      </div>
    </div>
  );
});

const CodeBlock = ({ language, value }) => {
  const codeRef = useRef();
  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    const clipboard = new Clipboard(codeRef.current, {
      text: () => value,
    });

    clipboard.on('success', () => {
      clipboard.destroy();
    });

    codeRef.current.click(); // Manually trigger the copy event

    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };


  return (
    <div>
      <div className="codeHeader">
        <span className="codeLanguage">{language}</span>
        <button
          ref={codeRef}
          className="copyCodeBtn"
          onClick={copyToClipboard}
        >
          {copied ? (
            <>
              <FaCheck />
              Copied!
            </>
          ) : (
            <>
              <FiCopy />
              Copy code
            </>
          )}
        </button>
      </div>
      <SyntaxHighlighter language={language} style={dracula} className="codeBox">
        {value}
      </SyntaxHighlighter>
    </div>
  );
};

const TableBlock = ({children, className,...props }) => {
  const tbl = useRef(null);
  const onDownload = useCallback(() => {
    const currentDate = new Date();
    const options = {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true
    };
    const currentDateTimeString = currentDate.toLocaleString('en-US', options).replace(/[,\/\s:]/g, '_');
    const elt = tbl.current;
    const wb = utils.table_to_book(elt);
    writeFileXLSX(wb, "ChatGPT " + currentDateTimeString + ".xlsx");
  }, [tbl]);

  const tableId = "ID" + uuidv4();
  const onDownloadPPT = useCallback(() => {
    const currentDate = new Date();
    const options = {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true
    };
    const currentDateTimeString = currentDate.toLocaleString('en-US', options).replace(/[,\/\s:]/g, '_');
    let pptx = new pptxgen();
    pptx.tableToSlides(tableId, {autoPage:true});
    pptx.writeFile({ fileName: "ChatGPT " + currentDateTimeString + ".pptx" });
  }, [tbl]);


  return (
      <div>

        <table ref={tbl} id={tableId} className={className} {...props}>
          {children}
        </table>
        <button title="Export to Excel" id="btnExport" className="rounded" style={{backgroundColor: "rgb(39, 39, 39)", color: "white", marginTop:10}} onClick={onDownload}><RiFileExcel2Line></RiFileExcel2Line></button> &nbsp;

      </div>
  );
};

const MarkdownMessage = ({ content }) => {
  return (
    <ReactMarkdown
      components={{
        code({ node, inline, className, children, ...props }) {
          const match = /language-(\w+)/.exec(className || '');
          let language = "";
          if (match) {
            language = match[1];
          }
          return !inline ? (
            <CodeBlock language={language} value={String(children).replace(/\n$/, '')} {...props} />
          ) : (
            <code className={{ className }} {...props}>
              {children}
            </code>
          );
        },
      }}
      remarkPlugins={[gfm]}
    >
      {content}
    </ReactMarkdown>
  );
};


// PDF Export
const handleExportToPDF = () => {
  // Get the target element by its ID or use a ref to the div element
  const element = document.getElementsByClassName('chat-container')[0];

  // Generate the current date and time string
  const currentDate = new Date().toLocaleDateString().replaceAll('/', '-');
  const currentTime = new Date().toLocaleTimeString().replace(/:/g, '-');
  const dateTime = `${currentDate}_${currentTime}`;
  let opt = {
    image: { type: 'jpeg' },
    html2canvas: { useCORS: true, scale: 2 },
    pagebreak: {
      mode: ['avoid-all', 'css', 'legacy']
    }
  }

  // Generate the PDF using html2pdf.js with the modified file name
  html2pdf().from(element).set(opt).save(`ChatGPTConversation_${dateTime}.pdf`);

};

function useDebounce(callback, delay) {
  const debounceRef = useRef(null);

  useEffect(() => {
    return () => {
      if (debounceRef.current) {
        clearTimeout(debounceRef.current);
      }
    };
  }, []);

  const debouncedCallback = (...args) => {
    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }
    debounceRef.current = setTimeout(() => {
      callback(...args);
    }, delay);
  };

  return debouncedCallback;
}

// WINDOW EVENTS
function useWindowWidth() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      const windowHeight = window.innerHeight;
      document.querySelector('.sidebar').style.height = windowHeight + "px"; // fix sidebar height issue on mobile when keyboard appears/disappears
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return windowWidth;
}

function Home() {
  const windowWidth = useWindowWidth();
  DisableTabbingOutsideOfConsentModals();

  // Set the title for the environment
  document.title = appTitle;

  GetConsent();
  checkTokenExpiration();

  return (
      <ProtectedComponent />
  );
}

export default Home;