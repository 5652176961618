import CloudentityAuth from '@cloudentity/auth';
import {Buffer} from "buffer";

const G3_ACCESS_TOKEN_NAME = "g3-access_token";
const G3_ID_TOKEN_NAME = "g3-id_token";
const G3_CE_BASE_URL = window._env_.REACT_APP_CLOUDENTITY_BASE_URL;
const G3_CE_AUTHORIZATION_SERVER_ID = window._env_.REACT_APP_CLOUDENTITY_AUTHORIZATION_SERVER_ID;
const G3_CE_CLIENT_ID = window._env_.REACT_APP_CLOUDENTITY_CLIENT_ID;
const G3_CE_REDIRECT_URL = window._env_.REACT_APP_CLOUDENTITY_REDIRECT_URL;

const cloudentity = new CloudentityAuth({
    baseUrl: G3_CE_BASE_URL,
    authorizationServerId: G3_CE_AUTHORIZATION_SERVER_ID,
    clientId: G3_CE_CLIENT_ID,
    redirectUri: G3_CE_REDIRECT_URL,
    scopes: ['email', 'introspect_tokens', 'openid', 'profile', 'revoke_tokens'],
    responseType: ['code'],
    idTokenName: G3_ID_TOKEN_NAME,
    accessTokenName: G3_ACCESS_TOKEN_NAME
});

function parseJwt (token) {
    return JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString());
}

function removeQueryParams() {
    const url = new URL(window.location);
    url.search = '';
    window.history.replaceState({}, '', url);
}

export function getAccessToken() {
    return cloudentity.getAccessToken();
}

export function checkTokenExpiration() {
    const accessToken = cloudentity.getAccessToken();
    const decodedToken = accessToken ? parseJwt(accessToken) : JSON.parse('{"exp": 0, "iat": 0}');
    const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
    const expirationTime = decodedToken.exp; // Expiration time in seconds
    const issuedTime = decodedToken.iat; // Issued at time in seconds
    const maxTokenDuration = expirationTime - issuedTime; // Total token duration in seconds
    const secondsUntilTokenExpires = expirationTime - currentTime;
    const minimumTokenTTLSeconds = maxTokenDuration * 0.2; // Set token refresh window to 20% of total token duration
    if (secondsUntilTokenExpires < minimumTokenTTLSeconds) {
        console.log(`Token will expire in ${secondsUntilTokenExpires} seconds, which is less than the minimum token TTL of ${minimumTokenTTLSeconds} seconds`);
        localStorage.removeItem("didConsent");
        if(accessToken) cloudentity.revokeAuth();
        getCloudentityAuth(true);
    } else {
        if(accessToken) getCloudentityAuth(false);
    }
}

export default async function getCloudentityAuth(authExpired = false) {
    const accessToken = cloudentity.getAccessToken();
    const didConsent = localStorage.getItem("didConsent");
    try {
        cloudentity.getAuth().then(
            function (authResponse) {
                if(authExpired) {
                    console.log("Auth expired, revoking auth and consent");
                    cloudentity.authorize();
                    window.location.reload();
                } else if(!authExpired && didConsent !== null) {
                    console.log("Auth did not expire and consent exists, nothing to do");
                }
            },
            function (errorResponse) {
                console.log("Auth error, revoking, authorizing, and reloading");
                if(accessToken) cloudentity.revokeAuth();
                cloudentity.authorize();
                window.location.reload();
            }
        )
    } catch (error) {
        console.log(`Error was`);
        console.log(error);
    } finally {
        removeQueryParams();
    }
};